import { Amplify, ResourcesConfig } from 'aws-amplify';

interface AuthPoolConfig {
	userPoolId: string;
	userPoolClientId: string;
}

const pools: Record<string, AuthPoolConfig> = {
	users: {
		userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID ?? '',
		userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID ?? '',
	},
	providers: {
		userPoolId: process.env.REACT_APP_AWS_PROVIDER_POOL_ID ?? '',
		userPoolClientId: process.env.REACT_APP_AWS_PROVIDER_POOL_CLIENT_ID ?? '',
	},
	admin: {
		userPoolId: process.env.REACT_APP_AWS_ADMIN_POOL_ID ?? '',
		userPoolClientId: process.env.REACT_APP_AWS_ADMIN_POOL_CLIENT_ID ?? '',
	},
};

const getAmplifyConfig: (userType: string) => ResourcesConfig = (userType: string) => {
	if (!pools[userType]) {
		throw new Error(`Tipo de usuario inválido: ${userType}`);
	}
	const domain = process.env.REACT_APP_AWS_COGNITO_DOMAIN ?? '';
	const baseUrl = window.location.origin;
	return {
		Auth: {
			Cognito: {
				userPoolId: pools[userType].userPoolId,
				userPoolClientId: pools[userType].userPoolClientId,
				identityPoolId: '',
				loginWith: {
					oauth: {
						providers: ['Google'],
						domain,
						scopes: ['openid', 'profile', 'email'],
						redirectSignIn: [`${baseUrl}/callback`],
						redirectSignOut: [`${baseUrl}/login`],
						responseType: 'code',
					},
					email: true,
				},
				mfa: {
					status: 'on',
					smsEnabled: true,
				},
				userAttributes: {
					email: {
						required: true,
					},
				},
				allowGuestAccess: false,
				passwordFormat: {
					minLength: 8,
					requireLowercase: true,
					requireUppercase: true,
					requireNumbers: true,
					requireSpecialCharacters: true,
				},
			},
		},
	};
};

export const configureAuth = (userType: string): void => {
	try {
		const currentConfig = localStorage.getItem('currentAmplifyConfig');

		Amplify.configure(getAmplifyConfig(userType));

		if (currentConfig !== userType) {
			localStorage.setItem('currentAmplifyConfig', userType);
		}
	} catch (error) {
		console.error('Error trying to configure Amplify', error);
	}
};
