import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Logo from '@assets/imgs/connect-logo.png';
import HeliosClient from '@api/HeliosClient';
import { Link, useHistory, useParams } from 'react-router-dom';

import './LoginOption.scss';
import { setStatusFilter, setUserType, setProvider, setUser } from '@context/actions';
import { Context } from '@context/Context';
import { AppContext } from '@interfaces';
import { DASHBOARD_SECTION, ROLE, USER_TYPE } from '@utils/constants';
import { fetchSituationTypes } from '@utils/ServiceType.utils';
import UserLogin from './components/UserLogin/UserLogin';

interface RouteParams {
	userType: string;
}

const LoginOption: FunctionComponent = (): JSX.Element => {
	const { dispatch } = useContext(Context) as AppContext;
	const [loginOptions, setLoginOption] = useState<string>('');
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [errors, setErrors] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const params = useParams<RouteParams>();
	const history = useHistory();

	useEffect(() => {
		setLoginOption(params.userType);
	}, []);

	const validateForm = async () => {
		if (!password) {
			toast.error('Password required');
			setErrors(true);
		} else if (!email) {
			toast.error('Username Required');
			setErrors(true);
		}

		if (errors) return false;
		setLoading(true);
	};

	const getRol = async (data: any) => {
		let response;

		try {
			if (['users', 'admin'].includes(loginOptions)) {
				response = await new HeliosClient().clientLogin(data);
				if (response.status && !response.data.data.user.hasSecurePassword) {
					history.push(`/reset/${loginOptions}/forgot-password`);
					return;
				}
				setUserType(dispatch, USER_TYPE.USER);
				const {
					_id,
					branch,
					email,
					firstname,
					lastname,
					roles,
					status,
					account,
					searchCriteria,
					fixedLocations,
					otherServicesAllowed,
					homeServicesAllowed,
				} = response.data.data.user;
				setUser(dispatch, {
					_id,
					branch,
					email,
					firstname,
					lastname,
					roles,
					status,
					account,
					searchCriteria,
					fixedLocations,
					otherServicesAllowed,
					homeServicesAllowed,
				});
			} else {
				response = await new HeliosClient().providerLogin(data);
				setUserType(dispatch, USER_TYPE.PROVIDER);
				const {
					_id,
					branch,
					email,
					name,
					phone,
					providerId,
					providerName,
					providerType,
					roles,
					status,
					vendorId,
					zones,
					eosActions,
					socialReason,
					taxId,
					billing,
				} = response.data.data.provider;
				setProvider(dispatch, {
					_id,
					branch,
					email,
					name,
					phone,
					providerId,
					providerName,
					providerType,
					roles,
					status,
					vendorId,
					zones,
					socialReason,
					taxId,
					eosActions,
					billing,
				});
			}

			const {
				status,
				data: {
					data: { user, provider },
				},
			} = response;

			if (status) {
				if (loginOptions === 'admin') {
					const adminRole = user.roles.find((role: string) => role === ROLE.ADMIN_EOS);
					if (adminRole) setStatusFilter(dispatch, DASHBOARD_SECTION.BILLING);
					else {
						toast.error('Error');
						return;
					}
				}
				fetchSituationTypes(dispatch, user?.branch || provider?.branch);
				history.replace('/dashboard');
			} else toast.error('Error');
		} catch (error) {
			toast.error('Please verify Email or Password');
		}
	};

	const login = async (event: any): Promise<any> => {
		event.preventDefault();
		setLoading(true);
		const data = {
			email,
			password,
		};

		await validateForm();

		await getRol(data);

		setLoading(false);
	};

	return (
		<div className="login-option">
			<figure>
				<img src={Logo} alt="connect logo" />
			</figure>
			{['users', 'providers', 'admin'].includes(loginOptions) ? (
				<UserLogin userType={loginOptions} />
			) : (
				<div className="login-form">
					<div className="login-form__header">
						<h4>
							Log in
							<br />
							<span>with your Connect Account</span>
						</h4>
					</div>
					<form onSubmit={login} className="login-form__body">
						<input
							className="login-form__icon login-form__icon--email"
							type="email"
							placeholder="Email"
							onChange={(evt): void => setEmail(evt.target.value)}
						/>
						<input
							className="login-form__icon login-form__icon--password"
							type="password"
							placeholder="Password"
							onChange={(evt): void => setPassword(evt.target.value)}
						/>
						<div className="login-form__forgot-password">
							<Link to={`/reset/${loginOptions}/forgot-password`}>
								<span>Forgot Password?</span>
							</Link>
						</div>
						<div className="login-form__footer">
							<button type="submit" className="login-form__btn">
								{loading ? <div className="login-form__btn--loading" /> : <div>Sign in</div>}
							</button>
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default LoginOption;
