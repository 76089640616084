import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import dayjs from 'dayjs';

import { TimestampOption, TimestampsProps } from './types';
import { setUpTimestamps } from './utils';
import { Title, FlexCenterItems, AddButton, List, Time, Container } from './styles';

import { AppContext } from '@interfaces';
import { formatTimestampLabel } from '@views/Service/Service.util';
import { Context } from '@context/Context';
import HeliosClient from '@api/HeliosClient';
import { BRANCH } from '@utils/constants';
import Feature from '@utils/FeatureFlags';
import { FEATURE_FLAG } from '@utils/FeatureFlags/constants';

const Timestamps: FunctionComponent<TimestampsProps> = ({ service }): JSX.Element => {
	const { provider, user } = useContext(Context) as AppContext;
	const [serviceStamps, setServiceStamps] = useState([]);
	const [stamps, setStamps] = useState<TimestampOption[]>([]);
	const [timestamp, setTimestamp] = useState<string>('');

	const branch = provider?.branch || user?.branch;

	const isDriverVehicleStores = service.driver?.vehicleType === 'stores';

	const activateTimestamps = service.status === 'Active' && Boolean(service.driver?.app?.version) === false && provider;

	useEffect(() => {
		setServiceStamps(service.trip.stamps);
		if (activateTimestamps) {
			const availableStamps = setUpTimestamps(service);
			setStamps(availableStamps);
		}
	}, [service]);

	const onTimestampClick = async (): Promise<void> => {
		const {
			data: { status, data },
		} = await new HeliosClient().addServiceTimestamp({
			_id: service._id,
			timestamp: {
				time: Date.now(),
				employeeId: provider._id,
				employeeName: provider.name,
				employeeEmail: provider.email,
				status: timestamp,
			},
		});
		if (status) {
			setServiceStamps(data.trip.stamps);
			setTimestamp('');
		}
	};
	return (
		<div>
			{/* @ts-expect-error - primereact type bug */}
			<Container>
				<FlexCenterItems>
					<Title>Timestamps</Title>
					{activateTimestamps && (isDriverVehicleStores || branch === BRANCH.COL) && (
						<FlexCenterItems>
							<Dropdown
								value={timestamp}
								options={stamps}
								onChange={(e) => setTimestamp(e.value)}
								optionLabel="label"
								optionValue="key"
								placeholder="Select new status"
							/>

							<AddButton
								icon="pi pi-plus"
								className="p-button-rounded"
								onClick={onTimestampClick}
								disabled={!timestamp}
								color="#ff5100"
							/>
						</FlexCenterItems>
					)}
				</FlexCenterItems>
				<Divider />
				<div>
					{serviceStamps.map((stamp: any, index: number) => (
						<List key={index} className="service-view__timestamps-row">
							<label className="text-bold">{formatTimestampLabel(stamp.status)}</label>
							<Feature name={FEATURE_FLAG.SERVICE_DETAIL__DRIVER_TIMESTAMP_INFO__SHOW}>
								<label className="text-bold">{stamp.employeeName}</label>
							</Feature>
							<Time>{dayjs(stamp.time).format('MMMM D, YYYY, h:mm:ss a')}</Time>
						</List>
					))}
				</div>
			</Container>
		</div>
	);
};

export default Timestamps;
