import React, { useEffect, useState } from 'react';
import { confirmSignIn, signIn, rememberDevice } from 'aws-amplify/auth';

import { toast } from 'react-toastify';
import './VerifyCode.scss';
import { Link } from 'react-router-dom';
interface VerifyCodeProps {
	email: string;
	password: string;
	onSuccess: () => Promise<void>;
}

const VerifyCode: React.FC<VerifyCodeProps> = ({ email, password, onSuccess }) => {
	const [code, setCode] = useState('');
	const [loading, setLoading] = useState(false);
	const [countdown, setCountdown] = useState(180);
	const [canRequestNewCode, setCanRequestNewCode] = useState(false);
	const [trustDevice, setTrustDevice] = useState(false);
	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (countdown > 0) {
			timer = setInterval(() => {
				setCountdown((prev) => prev - 1);
			}, 1000);
		} else {
			setCanRequestNewCode(true);
		}
		return () => clearInterval(timer);
	}, [countdown]);

	const handleRequestNewCode = async () => {
		try {
			await signIn({ username: email, password, options: { authFlowType: 'USER_PASSWORD_AUTH' } });
			setCanRequestNewCode(false);
			setCountdown(180);
			toast.success('A new code has been sent to your email. Please check your inbox.');
		} catch (error) {
			console.error(error);
			toast.error('There was an error requesting a new code');
		}
	};

	const handleConfirmSignIn = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		try {
			await confirmSignIn({ challengeResponse: code });
			if (trustDevice) {
				await rememberDevice();
			}
			await onSuccess();
		} catch (err: any) {
			console.error('Error at VerifyCode:', err);
			toast.error(err?.message || 'An error occurred during verification');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="verify-code-form">
			<Link to="/login" className="verify-code-form__back-btn">
				<i className="fa fa-arrow-left"></i> Back to Login
			</Link>
			<div className="verify-code-form__header">
				<h4>
					Verify it&apos;s you
					<br />
					<span>
						Enter the authentication code we
						<br /> sent to the registered email.
					</span>
				</h4>
			</div>
			<form onSubmit={handleConfirmSignIn} className="verify-code-form__body">
				<input
					className="verify-code-form__icon verify-code-form__icon--password"
					type="number"
					placeholder="Enter authentication code"
					value={code}
					onChange={(e) => {
						const value = e.target.value;
						if (value.length <= 6) {
							setCode(value);
						}
					}}
				/>
				<label className="verify-code-form__remember-device">
					<input type="checkbox" checked={trustDevice} onChange={(e) => setTrustDevice(e.target.checked)} />
					<span>Trust this device</span>
				</label>
				<div className="verify-code-form__resend">
					<a
						onClick={handleRequestNewCode}
						className={`verify-code-form__resend-link ${!canRequestNewCode ? 'disabled' : ''}`}
					>
						SOLICITAR NUEVO CÓDIGO
					</a>
					<br />
					{!canRequestNewCode && <span className="verify-code-form__countdown">({countdown}s)</span>}
				</div>
				<div className="verify-code-form__footer">
					<button type="submit" className="verify-code-form__btn">
						{loading ? <div className="verify-code-form__btn--loading" /> : <div>Verify</div>}
					</button>
				</div>
			</form>
		</div>
	);
};

export default VerifyCode;
