import React, { useEffect, useState } from 'react';
import { resetPassword } from 'aws-amplify/auth';
import './ForgotPassword.scss';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

import LoginArrow from '../../../../assets/imgs/login_arrow.png';
import ResetPasswordForm from '../ResetClientPassword/ResetClientPassword';

import { configureAuth } from '@utils/amplify';

const ForgotPassword: React.FC = () => {
	const [step, setStep] = useState<'request' | 'confirm'>('request');
	const [email, setEmail] = useState('');

	const { userType } = useParams<{ userType: string }>();
	useEffect(() => {
		if (userType) {
			console.log('userType', userType);
			configureAuth(userType);
		}
	}, [userType]);

	const handleRequestCode = async (e?: React.FormEvent) => {
		e?.preventDefault();

		if (!email) {
			toast.error('Email required');

			return;
		}

		try {
			await resetPassword({ username: email });

			setStep('confirm');
		} catch (err: any) {
			console.error('Error at ForgotPassword:', err);
			if (err?.name === 'UserNotFoundException') {
				toast.error('We cannot find an account with that email address');
			} else {
				toast.error(err?.message || 'An error occurred during password reset');
			}
		}
	};

	if (step === 'confirm') {
		return <ResetPasswordForm email={email} onRequestNewCode={handleRequestCode} />;
	}
	return (
		<div className="forgot-page">
			<form onSubmit={handleRequestCode} className="forgot-page__form">
				<div className="forgot-page__form__header">
					<h5>Forgot Password</h5>
				</div>
				<div className="forgot-page__form__body">
					<span>Enter your email address to request a password recovery</span>
					<input
						className="forgot-page__form__icon forgot-page__form__icon--email"
						type="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<div className="forgot-page__forgot-password">
						<Link to="/login">
							<i className="fa fa-arrow-left"></i>
							<span> Back to Login</span>
						</Link>

						<figure>
							<img onClick={handleRequestCode} src={LoginArrow} alt="login button" />
						</figure>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ForgotPassword;
