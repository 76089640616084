// External imports
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// Global imports
import Feature from '@utils/FeatureFlags';
import { FEATURE_FLAG } from '@utils/FeatureFlags/constants';
// Local imports
import './styles.scss';
import CreateService from '@assets/imgs/create-service.svg';
import Report from '@assets/imgs/report.svg';
import DashboardIcon from '@assets/imgs/dashboard.png';
import Pin from '@assets/imgs/pin.png';
import Logout from '@assets/imgs/logout.png';
import localforage from 'localforage';
import { Context } from '@context/Context';
import { ActionType, ActiveService, AppContext, HttpDriver, Provider } from '@interfaces';
import HeliosClient from '@api/HeliosClient';
import { fetchFeatureFlags } from '@utils/FeatureFlags/utils';
import NavLink from './nav-link';
import { signOut } from 'aws-amplify/auth';

export const getFilteredDrivers = (
	drivers: HttpDriver[],
	activeServices: ActiveService[],
	provider?: Provider | undefined
): any => {
	if (!drivers || !activeServices) return {};
	const isProvider = Boolean(provider);

	const driversArr: any = [];
	for (const driver of drivers) {
		if (isProvider && driver.provider.vendorId === provider?.vendorId) {
			driversArr.push(driver);
		} else if (!isProvider) {
			// is corporate user
			for (const service of activeServices) {
				if (service.driver.id === driver._id) driversArr.push(driver);
			}
		}
	}

	return driversArr.length;
};

const Navbar: FunctionComponent = (): JSX.Element => {
	const { dispatch, user, provider } = useContext(Context) as AppContext;
	const [serviceNumber, setServiceNumber] = useState<string>();
	const [liveDrivers, setLiveDrivers] = useState<HttpDriver[]>([]);
	const [activeServices, setActiveServices] = useState<ActiveService[]>([]);
	const branch = user?.branch || provider?.branch;
	const history = useHistory();
	const goToService = (): void => {
		history.push(`/services/${serviceNumber}`);
	};

	const fetchLiveDrivers = async () => {
		const {
			data: { data },
		} = await new HeliosClient().getActiveDrivers(branch);
		setLiveDrivers(data);
	};

	const fetchActiveServices = async () => {
		const queryParams: any = {
			branch: branch,
		};
		const {
			data: { data },
		} = await new HeliosClient().getActiveServices(queryParams);
		setActiveServices(data);
	};

	useEffect(() => {
		fetchLiveDrivers();
		fetchActiveServices();
		const liveDriversTimer = setInterval(fetchLiveDrivers, 100000);
		const activeServicesTimer = setInterval(fetchActiveServices, 100000);
		return () => {
			clearInterval(activeServicesTimer);
			clearInterval(liveDriversTimer);
		};
	}, []);

	const logout = async (): Promise<void> => {
		localforage.clear().then(async () => {
			try {
				await signOut();
			} finally {
				history.replace('/login');
				dispatch({ type: ActionType.LOGOUT });
				fetchFeatureFlags(dispatch);
			}
		});
	};

	return (
		<nav className="navbar">
			<div className="navbar__search-input">
				<input
					type="text"
					placeholder="Search"
					onChange={(evt): void => setServiceNumber(evt.target.value)}
					aria-label={'searchBar'}
				/>
				<button onClick={goToService} className="navbar__btn" aria-label={'searchBtn'}>
					<i className="fa fa-search"></i>
				</button>
			</div>
			{getFilteredDrivers(liveDrivers, activeServices, provider) > 0 && (
				<Link to="/map" className="navbar__drivers">
					<p>
						{getFilteredDrivers(liveDrivers, activeServices, provider)}{' '}
						{getFilteredDrivers(liveDrivers, activeServices, provider) > 2 ? 'Drivers' : 'Driver'} Connected
					</p>
				</Link>
			)}
			<div className="navbar__links">
				<Feature name={FEATURE_FLAG.DISPATCH_PAGE}>
					<NavLink to="/clients/dispatch" icon={CreateService} label="create-service" />
				</Feature>
				<Link to="/report">
					<img height="35" src={Report} aria-label="report" />
				</Link>
				<Link to="/dashboard">
					<img height="35" src={DashboardIcon} aria-label="dashboard" />
				</Link>
				<Link to="/map">
					<img height="35" src={Pin} aria-label="map" />
				</Link>
				<button onClick={logout} aria-label="logout">
					<img height="35" src={Logout} />
				</button>
			</div>
		</nav>
	);
};

export default Navbar;
