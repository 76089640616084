import React, { useContext, useState } from 'react';
import { signIn, signOut, signInWithRedirect } from 'aws-amplify/auth';
import VerifyCode from '../VerifyCode/VerifyCode';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import googleIcon from '@assets/imgs/google.svg';
import './UserLogin.scss';
import { setUserType } from '@context/actions';
import HeliosClient from '@api/HeliosClient';
import { setUser } from '@context/actions';
import { setProvider } from '@context/actions';
import { USER_TYPE } from '@utils/constants';
import { fetchSituationTypes } from '@utils/ServiceType.utils';
import { AppContext } from '@interfaces';
import { Context } from '@context/Context';
interface UserLoginProps {
	userType: string;
}
const UserLogin: React.FC<UserLoginProps> = ({ userType }) => {
	const [step, setStep] = useState<'signIn' | 'confirmSignIn'>('signIn');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const history = useHistory();
	const { dispatch } = useContext(Context) as AppContext;
	const validateForm = () => {
		if (!email) return 'El email es requerido';
		if (!password) return 'La contraseña es requerida';
		if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return 'Email inválido';
		return null;
	};

	const handleGoogleSignIn = async () => {
		try {
			await signOut();
			await signInWithRedirect({ provider: 'Google' });
		} catch (error: any) {
			console.error('Error at handleGoogleSignIn:', error);
			toast.error('Error trying to sign in with Google');
		}
	};

	async function handleSuccessfulLogin() {
		const response = await new HeliosClient().clientLoginWithToken();
		const user = response.data.data.user;
		const userType = user.type || 'user';
		setUserType(dispatch, userType);
		if (userType === USER_TYPE.USER) {
			const {
				_id,
				branch,
				email,
				firstname,
				lastname,
				roles,
				status,
				account,
				searchCriteria,
				fixedLocations,
				otherServicesAllowed,
				homeServicesAllowed,
			} = user;
			setUser(dispatch, {
				_id,
				branch,
				email,
				firstname,
				lastname,
				roles,
				status,
				account,
				searchCriteria,
				fixedLocations,
				otherServicesAllowed,
				homeServicesAllowed,
			});
		} else {
			const {
				_id,
				branch,
				email,
				name,
				phone,
				providerId,
				providerName,
				providerType,
				roles,
				status,
				vendorId,
				zones,
				eosActions,
				socialReason,
				taxId,
				billing,
			} = user;
			setProvider(dispatch, {
				_id,
				branch,
				email,
				name,
				phone,
				providerId,
				providerName,
				providerType,
				roles,
				status,
				vendorId,
				zones,
				socialReason,
				taxId,
				eosActions,
				billing,
			});
		}
		fetchSituationTypes(dispatch, user?.branch);
		history.replace('/dashboard');
	}

	const handleSignIn = async (e: React.FormEvent) => {
		e.preventDefault();
		await signOut();
		const validationError = validateForm();
		if (validationError) {
			toast.error(validationError);
			return;
		}
		setLoading(true);
		try {
			// First attempt without authFlowType
			const result = await signIn({
				username: email.trim(),
				password,
			});
			await handleSignInResult(result);
		} catch (err: any) {
			if (err?.name === 'UserNotFoundException') {
				try {
					// Second attempt with USER_PASSWORD_AUTH
					const result = await signIn({
						username: email.trim(),
						password,
						options: { authFlowType: 'USER_PASSWORD_AUTH' },
					});
					await handleSignInResult(result);
				} catch (retryErr: any) {
					handleSignInError(retryErr);
				}
			} else {
				handleSignInError(err);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleSignInResult = async (result: any) => {
		switch (result.nextStep.signInStep) {
			case 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE':
				setStep('confirmSignIn');
				toast.success('A code has been sent to your email. Please check your inbox.');
				break;
			case 'DONE':
				await handleSuccessfulLogin();
				break;
		}
	};
	const handleSignInError = (err: any) => {
		console.error('Error at UserLogin:', err);
		if (err?.name === 'UserNotFoundException') {
			toast.error('We cannot find an account with that email address');
		} else {
			toast.error(err?.message || 'An error occurred during login');
		}
	};

	if (step === 'confirmSignIn') {
		return <VerifyCode email={email} password={password} onSuccess={handleSuccessfulLogin} />;
	}

	if (userType === 'admin') {
		return (
			<div className="login-form">
				<div className="login-form__header admin-header">
					<h4>Sign in</h4>
					<p className="admin-subheader">
						To get started quickly and easily, sign in with your Google account. It&apos;s{' '}
						<span className="admin-highlight">fast</span>, <span className="admin-highlight">secure</span>, and{' '}
						<span className="admin-highlight">hassle-free</span>!
					</p>
				</div>
				<button type="button" onClick={handleGoogleSignIn} className="admin-google-btn">
					<img src={googleIcon} alt="Google" />
					Sign in with Google
				</button>
			</div>
		);
	}

	return (
		<div className="login-form">
			<div className="login-form__header">
				<h4>
					Log in
					<br />
					<span>with your Connect Account</span>
				</h4>
			</div>
			<form onSubmit={handleSignIn} className="login-form__body">
				<input
					className="login-form__icon login-form__icon--email"
					type="email"
					placeholder="user@domain.com"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<div className="login-form__password-container">
					<input
						className="login-form__icon login-form__icon--password"
						type={showPassword ? 'text' : 'password'}
						value={password}
						placeholder="Password"
						onChange={(e) => setPassword(e.target.value)}
					/>
					<i
						className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
						onClick={() => setShowPassword(!showPassword)}
					/>
				</div>
				<div className="login-form__forgot-password">
					<Link to={`/users/${userType}/forgot-password`}>
						<span>Forgot Password?</span>
					</Link>
				</div>
				<div className="login-form__footer">
					<button type="submit" className="login-form__btn">
						{loading ? <div className="login-form__btn--loading" /> : <div>Sign in</div>}
					</button>
				</div>
			</form>
		</div>
	);
};

export default UserLogin;
