import { FunctionComponent, useContext, useEffect } from 'react';
import localforage from 'localforage';
import { useHistory } from 'react-router';
import { ActionType, AppContext } from '@interfaces';
import { Context } from '@context/Context';
import { fetchFeatureFlags } from './FeatureFlags/utils';
import { toast } from 'react-toastify';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

export const AuthGuard: FunctionComponent<{ component: JSX.Element }> = ({ component }): JSX.Element => {
	const { dispatch } = useContext(Context) as AppContext;
	const history = useHistory();

	const doesTokenExist = async (): Promise<boolean> => {
		const token: any = await localforage.getItem('token');
		return Boolean(token) && Boolean(token.length);
	};

	const checkAmplifySession = async (): Promise<boolean> => {
		try {
			const session = await fetchAuthSession({ forceRefresh: true });

			if (session.tokens?.accessToken && session.tokens.accessToken.payload?.exp) {
				const expiresIn = session.tokens.accessToken.payload.exp * 1000;
				localforage.setItem('token', session.tokens.accessToken.toString());
				localforage.setItem('expires', expiresIn);
				return true;
			}
			return false;
		} catch (error) {
			console.error('Error checking Amplify session:', error);
			return false;
		}
	};

	const isTokenExpired = async (): Promise<boolean> => {
		const expires: any = parseInt((await localforage.getItem('expires')) || '0');

		if (!expires || Number.isNaN(expires)) {
			return true;
		}
		return Date.now() > expires;
	};

	const logout = async (): Promise<void> => {
		localforage.clear().then(async () => {
			try {
				await signOut();
			} finally {
				history.replace('/login');
				dispatch({ type: ActionType.LOGOUT });
				fetchFeatureFlags(dispatch);
			}
		});
	};

	const checkAuthorizationToken = async (): Promise<void> => {
		const [tokenExists, tokenIsExpired] = await Promise.all([doesTokenExist(), isTokenExpired()]);
		if (!tokenExists || tokenIsExpired) {
			const amplifySessionValid = await checkAmplifySession();
			if (!amplifySessionValid) {
				toast.error('Su sesión ha expirado, por favor inicie sesión nuevamente');
				await logout();
			}
		}
	};

	useEffect(() => {
		const interval = setInterval(checkAuthorizationToken, 30000);
		return () => clearInterval(interval);
	}, []);

	return component;
};

export const PublicRouteGuard: FunctionComponent<{ component: JSX.Element }> = ({ component }): JSX.Element => {
	const history = useHistory();

	const checkAuthentication = async (): Promise<void> => {
		try {
			const token: any = await localforage.getItem('token');
			const expires: any = parseInt((await localforage.getItem('expires')) || '0');

			if (token && expires && Date.now() < expires) {
				history.replace('/dashboard');
			}
		} catch (error) {
			console.log('PublicRouteGuard', error);
			return;
		}
	};

	useEffect(() => {
		checkAuthentication();
	}, []);

	return component;
};
