import React, { useEffect, useState } from 'react';
import { confirmResetPassword } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import './ResetClientPassword.scss';

import Logo from '../../../../assets/imgs/connect-logo.png';
interface ResetPasswordFormProps {
	email: string;
	onRequestNewCode: () => Promise<void>;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ email, onRequestNewCode }) => {
	const [code, setCode] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [countdown, setCountdown] = useState(180);
	const [canRequestNewCode, setCanRequestNewCode] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (countdown > 0) {
			timer = setInterval(() => {
				setCountdown((prev) => prev - 1);
			}, 1000);
		} else {
			setCanRequestNewCode(true);
		}
		return () => clearInterval(timer);
	}, [countdown]);

	const isPasswordValid = (password: string): boolean => {
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumbers = /\d/.test(password);
		const hasSpecialChar = /[_\W]/.test(password);

		return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar;
	};

	const handleRequestNewCode = async () => {
		try {
			await onRequestNewCode();
			setCanRequestNewCode(false);
			setCountdown(180);
			toast.success('A new code has been sent to your email. Please check your inbox.');
		} catch (error) {
			console.error(error);
			toast.error('There was an error requesting a new code');
		}
	};

	const handleConfirmReset = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		if (!code || !newPassword || !confirmPassword) {
			toast.error('All fields are required');
			setLoading(false);
			return;
		}

		if (newPassword !== confirmPassword) {
			toast.error('Passwords do not match');
			setLoading(false);
			return;
		}

		if (!isPasswordValid(newPassword)) {
			toast.error(
				'Password must be at least 8 characters long and contain uppercase, lowercase, numbers and special characters'
			);
			setLoading(false);
			return;
		}

		try {
			await confirmResetPassword({
				username: email,
				confirmationCode: code,
				newPassword,
			});

			toast.success('Password reset successful');
			history.push('/login');
		} catch (err: any) {
			console.error('Error at ResetClientPassword:', err);
			toast.error(err?.message || 'An error occurred during password reset');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="reset-client-page">
			<Link to="/login" className="reset-client-page__back-btn">
				<i className="fa fa-arrow-left"></i> Back to Login
			</Link>
			<figure>
				<img src={Logo} alt="connect logo" />
			</figure>
			<form onSubmit={handleConfirmReset} className="reset-client-page__form">
				<div className="reset-client-page__form__header">
					<h5>Change your password</h5>
					<p>1- Enter the authentication code we sent to the registered email.</p>
				</div>
				<div className="reset-client-page__form__body">
					<input
						className="reset-client-page__form__icon reset-client-page__form__icon--email"
						type="number"
						placeholder="Enter verification code"
						value={code}
						onChange={(e) => {
							const value = e.target.value;
							if (value.length <= 6) {
								setCode(value);
							}
						}}
					/>
					<p>2- Set your new password.</p>
					<div className="reset-client-page__form__password-container">
						<input
							className="reset-client-page__form__icon reset-client-page__form__icon--password"
							type={showNewPassword ? 'text' : 'password'}
							placeholder="New password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
						<i
							className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`}
							onClick={() => setShowNewPassword(!showNewPassword)}
						/>
					</div>
					<div className="reset-client-page__form__password-container">
						<input
							className="reset-client-page__form__icon reset-client-page__form__icon--password"
							type={showConfirmPassword ? 'text' : 'password'}
							placeholder="Re-enter new password"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<i
							className={`fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}
							onClick={() => setShowConfirmPassword(!showConfirmPassword)}
						/>
					</div>
					<div className="reset-client-page__form__text-list-header">
						<p>In order to protect your account, make sure your password:</p>
						<ul className="reset-client-page__form__text-list">
							<li>Is longer than 8 characters.</li>
							<li>Contains at least 1 number.</li>
							<li>Contains at least 1 special character.</li>
							<li>Contains at least 1 uppercase letter.</li>
							<li>Contains at least 1 lowercase letter. </li>
						</ul>
					</div>
					<div className="reset-client-page__form__resend">
						<a
							onClick={handleRequestNewCode}
							className={`reset-client-page__form__resend-link ${!canRequestNewCode ? 'disabled' : ''}`}
						>
							SOLICITAR NUEVO CÓDIGO
						</a>
						<br />
						{!canRequestNewCode && <span className="reset-client-page__form__countdown">({countdown}s)</span>}
					</div>
					<div className="reset-client-page__form__footer">
						<button type="submit" className="reset-client-page__form__btn">
							{loading ? <div className="reset-client-page__form__btn--loading" /> : <div>Change password</div>}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ResetPasswordForm;
